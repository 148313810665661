import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import { Form, Input } from 'formsy-react-components';
import Formm from "../components/Formm"
const Question = () => {
  const { wpPage } = useStaticQuery(graphql`
    {
        wpPage(slug: {eq: "options"}) {
            question {
              questionHeading
              questionHeadingTag
              questionText
            }
          }
    }
  `)

  return ( 
   <Container className="question-section" id="quote">
       <Row>
           <Col lg="6">
            <wpPage.question.questionHeadingTag>{wpPage.question.questionHeading}</wpPage.question.questionHeadingTag>
            <div className="options-text" dangerouslySetInnerHTML={{__html: wpPage.question.questionText}}></div>
           </Col>
           <Col lg="6"> 
               <Formm></Formm>
           </Col>
       </Row>
   </Container>
  )
}

export default Question
